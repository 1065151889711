import React from "react";
import {graphql} from "gatsby";
import SiteLayout from "../components/layouts/SiteLayout";
import {Box, Button, Grid, Typography} from "@mui/material";
import Section from "../components/molecules/Section";
import WarsawMap from "../assets/images/map-waw.svg";
import WroclawMap from "../assets/images/map-wro.svg";
import LodzMap from "../assets/images/map-ldz.svg";

export default function JakDojechacPage() {
  return (
    <SiteLayout>
      <Section>
        <Grid container spacing={4} sx={{p: 4}}>
          <Grid item md={6} lg={4} xs={12}>
            <Typography
              color="primary"
              variant="h1"
              sx={{mb: 2}}
            >
              Jak do nas trafić we Wrocławiu?
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{mb: 3, mt: 2}}
              target="_blank"
              href="https://goo.gl/maps/EPXX4N86ibhtwyyTA"
              rel="noreferrer"
            >
              Włącz Google Maps
            </Button>

            <Typography variant="body1">al. Armii Krajowej 46ad</Typography>
            <Typography variant="body1">50-541 Wrocław</Typography>
            <Typography variant="body1">
                tel:{" "}
              <a href="tel:0048506973850">
                +48 506 973 850
              </a>
            </Typography>
            <Typography variant="body1">
              <a href="mailto:dok@dokdok.pl">
                dok@dokdok.pl
              </a>
            </Typography>
            <br/>
            <Typography variant="body2">Godziny otwarcia przychodni:</Typography>
            <Typography variant="body2">dni robocze 8:00 - 18:00</Typography>
            <br/>
            <Typography variant="body2">Punkt pobrań czynny:</Typography>
            <Typography variant="body2">dni robocze 8:00 - 10:00</Typography>
          </Grid>
          <Grid item md={6} lg={8} sx={{display: {xs: "none", md: "block"}}}>
            <Box id="wroclaw">
              <WroclawMap/>
            </Box>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Grid container spacing={4} sx={{p: 4}}>
          <Grid item md={6} lg={4} xs={12}>
            <Typography color="primary" variant="h1">Jak do nas trafić w Warszawie?</Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{mb: 3, mt: 2}}
              target="_blank"
              href="https://goo.gl/maps/vycQviT6zrSJaksz6"
              rel="noreferrer"
            >
              Włącz Google Maps
            </Button>

            <Typography variant="body1">budynek Marynarska Point 2</Typography>
            <Typography variant="body1">ul. Postępu 15c</Typography>
            <Typography variant="body1">02-676 Warszawa</Typography>
            <Typography variant="body1">
              tel:{" "}
              <a href="tel:0048506973850">
                +48 506 973 850
              </a>
            </Typography>
            <Typography variant="body1">
              <a href="mailto:dok@dokdok.pl">
                dok@dokdok.pl
              </a>
            </Typography>
            <br/>
            <Typography variant="body2">Godziny otwarcia przychodni:</Typography>
            <Typography variant="body2">dni robocze 8:00 - 18:00</Typography>
            <br/>
            <Typography variant="body2">Punkt pobrań czynny:</Typography>
            <Typography variant="body2">dni robocze 8:00 - 10:00</Typography>
          </Grid>
          <Grid item md={6} lg={8} sx={{display: {xs: "none", md: "block"}}}>
            <Box id="warszawa">
              <WarsawMap/>
            </Box>
          </Grid>
        </Grid>
      </Section>
    </SiteLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
