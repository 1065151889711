import React from "react";
import {Box, BoxProps, Container, useTheme} from "@mui/material";

interface SectionProps {
  background?: "light" | "dark";
  fullWidth?: boolean;
  padding?: BoxProps["padding"];
  id?: string;
}

export default function Section(props: React.PropsWithChildren<SectionProps>) {
  const theme = useTheme();
  const bgColor = props.background ? theme.palette.primary[props.background] : "unset"

  return (
    <Box
      component="section"
      id={props.id}
      bgcolor={bgColor}
      sx={{
        position: "relative"
      }}
    >
      <Container maxWidth={props.fullWidth ? false : "xl"} disableGutters sx={{ padding: props.padding }}>
        {props.children}
      </Container>
    </Box>
  )
}